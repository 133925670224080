import React, { useState, useEffect } from 'react';
import { formatDate, formatMedicalNotes } from '../utility/module.js';

const PatientHistoryTabContent = ({ bookingData }) => {
  const [patientHistory, setPatientHistory] = useState([]);
  const [user, setUser] = useState('');


  useEffect(() => {
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setUser(data.user)
          // fetchWorkSchedule(currentDate); // Fetch work schedules for the initial date on page load
        } else {
          window.location.href = '/login';
        }
      });
  }, []);

  useEffect(() => {
    // Fetch patient history
    fetch('/api/admin/getPatientHistory', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: bookingData.patient.uuid,
      })
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data)
        setPatientHistory(data);
      })
      .catch(error => {
        console.error('Error fetching patient history:', error);
      });
  }, [bookingData.patient.uuid]);

  return (
    <div className='adminParent adminTabSection adminTable'>
      <h3>Patient History</h3>
      <hr />
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Medical Info Summary</th>
          </tr>
        </thead>
        <tbody>
          {patientHistory.map((history, index) => (
            <tr key={index}>
              <td>{formatDate(history.startTime)} <br/>
                <br/>
                Status: {history.status} <br />
                <br />
                {
                  history.servicePerformeds
                    ? <>{history.servicePerformeds.map((eaSP) => <>{eaSP.service && eaSP.service.name} <br /></>)} </>
                    : ''
                }
                <br/>
                {user.acl > 3 && <a href={`/admin/bookingNew/${history.uuid}`}><button>Click to View</button></a>}
              </td>
              <td>{formatMedicalNotes(Object.keys(history.patientMedicalNotes || {}).sort().reduce((obj, key) => {
                obj[key] = history.patientMedicalNotes[key];
                return obj;
              }, {}))}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {patientHistory.length === 0 && <p>No patient history available.</p>}
    </div>
  );
};

export default PatientHistoryTabContent;
