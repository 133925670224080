// src/components/CancelBookingForm.jsx
import React, { useState } from 'react';
import { updateBooking as updateBookingApi, cancelBooking as cancelBookingApi } from '../../api/bookingApi';

const CancelBookingForm = ({ localBookingData, bookingData, user }) => {
  const [cancelNoteReason, setCancelNoteReason] = useState('');
  const [cancelNoteText, setCancelNoteText] = useState('');

  const handleCancelBooking = async (e) => {
    e.preventDefault();
    const newNote = {
      reason: cancelNoteReason,
      text: cancelNoteText,
      date: new Date().toISOString(),
      author: user.email,
      provider: bookingData.primaryStaffId,
    };

    // Append new note to customer service communication JSON
    const updatedNotes = bookingData.customerServiceCommunicationJSON
      ? [...bookingData.customerServiceCommunicationJSON, newNote]
      : [newNote];

    try {
      await updateBookingApi({
        uuid: localBookingData.uuid,
        customerServiceCommunicationJSON: updatedNotes,
      });
      await cancelBookingApi({
        uuid: localBookingData.uuid,
        reason: cancelNoteReason || 'Cancelled by user',
      });
    } catch (error) {
      console.error('Error cancelling booking:', error);
    }
    setCancelNoteReason('');
    setCancelNoteText('');
  };

  return (
    <div className="adminTextField">
      <h3>Cancel Booking</h3>
      <form onSubmit={handleCancelBooking}>
        <label>
          Reason:{' '}
          <select
            value={cancelNoteReason}
            onChange={(e) => {
              setCancelNoteText(e.target.options[e.target.selectedIndex].id);
              setCancelNoteReason(e.target.value);
            }}
            required
          >
            <option value="">Please select a reason</option>
            <option value="Nurse missed" id="Nurse missed">
              Nurse missed IV and patient canceled
            </option>
            <option value="Patient Canceled - late" id="Patient Canceled - late">
              Patient canceled because we were running late
            </option>
            <option value="Patient canceled due to travel fee" id="Patient canceled due to travel fee">
              Patient canceled due to travel fee
            </option>
            <option value="Patient canceled - favorite nurse unavailable" id="Patient canceled - favorite nurse unavailable">
              Patient canceled - favorite nurse unavailable
            </option>
            <option value="Bad Patient" id="Rambunctious Patient">
              Luxe - Canceled because we do not like the patient
            </option>
            <option value="Outside Service Area" id="Outside Service Area">
              Outside service area
            </option>
            <option value="Patient unreachable at time of appointment" id="Patient unreachable at time of appointment">
              Patient unreachable at time of appointment
            </option>
            <option value="Patient Health History" id="Patient Health History">
              Patient health history doesn't allow drip
            </option>
            <option value="Patient unreachable to confirm before appointment" id="Patient unreachable to confirm before appointment">
              Patient unreachable to confirm before appointment
            </option>
            <option value="test booking" id="test booking">
              Test booking
            </option>
            <option value="Patient Canceled - other - Note required" id="Patient Canceled - other">
              Patient canceled - other reason (please specify in notes)
            </option>
            <option value="Wrong Patient" id="Wrong Patient (Recreated under new name)">
              Wrong Patient (Recreated under new name)
            </option>
          </select>
        </label>
        <br />
        <button type="submit" disabled={!cancelNoteReason}>
          Cancel Booking
        </button>
      </form>
    </div>
  );
};

export default CancelBookingForm;