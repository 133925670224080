import React, { useState, useEffect } from 'react';
// import TreeRenderer from '../tree/TreeRenderer'
import TreeRendererFromDB from '../tree/TreeRendererFromDB'
import Modal from 'react-modal';
import TreeViewSummary from '../tree/TreeViewSummary';

const MedicalTabContent = ({ bookingData, handleFieldChange, setChangeMade }) => {
  const initialNurseNotes = bookingData.nurseNotes ? JSON.parse(bookingData.nurseNotes) : [];
  const initialData = bookingData.patientMedicalNotes
    ? bookingData.patientMedicalNotes
    : {
        PMHx: '',
        Meds: '',
        Allergies: '',
        BloodPressure: '',
        HeartRate: '',
        PulseOx: '',
        IVAttempts: '',
        IVPlacement: '',
        IVGauge: '18g',
        FluidType: 'NS',
        StartTime: '',
        StopTime: '',
        FluidVolume: '',
        NurseNotes: initialNurseNotes
      };
  const [user, setUser] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTreeModalOpen, setIsTreeModalOpen] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [currentTree, setCurrentTree] = useState(null);
  const [medicalData, setMedicalData] = useState(initialData);
  const [newNurseNote, setNewNurseNote] = useState(''); // State for new nurse note
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [appointmentType, setAppointmentType] = useState('IV');
  const [requiredFields, setRequiredFields] = useState([
    'PMHx', 'Meds', 'Allergies', 'BloodPressure', 'HeartRate',
    'PulseOx', 'IVAttempts', 'IVPlacement', 'IVGauge', 'FluidType',
    'StartTime', 'StopTime'
  ]);
  const [summaryKey, setSummaryKey] = useState(0);

  useEffect(() => {
    // console.log('bookingData in useEffect in medicalTabContent:', bookingData)
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setUser(data.user)
          if (!bookingData?.extraStuff?.nurseArrivalTime) {
            setWarningModalOpen(true)
          }
        } else {
          window.location.href = '/login';
        }
      });
  }, []);

  const handleNurseNoteChange = (event) => {
    setNewNurseNote(event.target.value);
    setChangeMade(true)
  };

  const openForm = (treeName) => {
    setCurrentTree(treeName);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    // setCurrentTree(null);
  };

  const closeWarningModal = () => {
    setWarningModalOpen(false);
    // setCurrentTree(null);
  };

  const handleChange = (event) => {
    // ensure the nurse has marked the booking as arrived before allowing changes
    if (!bookingData?.extraStuff?.nurseArrivalTime) {
      setWarningModalOpen(true);
    } else {
      setChangeMade(true)
      if (event.target.name === 'NurseNotes') {
        // Handle changes specifically for NurseNotes
        const newNote = { date: new Date().toISOString(), note: event.target.value };
        setMedicalData({
          ...medicalData,
          NurseNotes: [...medicalData.NurseNotes, newNote]
        });
      } else {
        // Handle changes for other fields
        setMedicalData({
          ...medicalData,
          [event.target.name]: event.target.value
        });
      }
    }
  };

  // required field check use effect
  useEffect(() => {

    const areRequiredFieldsFilled = requiredFields.every(field =>
      medicalData[field] && medicalData[field].trim() !== ''
    );

    setIsSaveButtonDisabled(!areRequiredFieldsFilled);
  }, [medicalData]);

  const updateMedInfo = (event) => {
    const button = event.target;
    // Add new nurse note to the list of notes
    const updatedNurseNotes = [
      ...medicalData.NurseNotes,
      { date: new Date().toISOString(), note: newNurseNote }
    ];

    let updatedMedicalData;
    if (newNurseNote.length > 1) {
      // Update medical data with new nurse notes
      updatedMedicalData = {
        ...medicalData,
        NurseNotes: updatedNurseNotes
      };
    } else {
      updatedMedicalData = {
        ...medicalData
      };
    }

    // Call API to save updated medical data
    fetch('/api/admin/updateBooking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: bookingData.uuid,
        patientMedicalNotes: updatedMedicalData
      })
    })
      // .then(response => response.json())
      .then(async response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setMedicalData(updatedMedicalData); // Update state with saved data
        handleFieldChange('patientMedicalNotes', updatedMedicalData); // Update state with saved data
        setNewNurseNote(''); // Clear new nurse note input
        setChangeMade(false)
        button.classList.add('green');
        setTimeout(() => button.classList.remove('green'), 3000);
      });
  };

  const openTreeForm = (treeId) => {
    setCurrentTree(treeId);
    setIsTreeModalOpen(true);
  };

  const closeTreeModal = () => {
    setIsTreeModalOpen(false);
    setSummaryKey(prevKey => prevKey + 1);
    // reload the TreeViewSummary component
  };

  return (
    <div className='adminParent adminTabSection'>
      <Modal isOpen={warningModalOpen} onRequestClose={closeModal} style={{ content: { width: '100%', height: '100%' } }}>
          <p className='todo'>You need to indicate you've arrived before entering medical information</p>
          <button onClick={closeWarningModal}> Close</button>
      </Modal>
      <button onClick={updateMedInfo} className='saveButton'>Save</button> <br/>
      <div>
        <table>
          <tbody>
            <tr>
              <td><label>Appointment Type:</label></td>
              <td>
              <label>
                <input
                type="radio"
                name="appointmentType"
                value="IV"
                checked={appointmentType === 'IV'}
                onChange={() => {
                  setAppointmentType('IV')
                  setRequiredFields([[
                    'PMHx', 'Meds', 'Allergies', 'BloodPressure', 'HeartRate',
                    'PulseOx', 'IVAttempts', 'IVPlacement', 'IVGauge', 'FluidType',
                    'StartTime', 'StopTime'
                  ]])
                }}
                />
                IV
              </label>
              <label>
                <input
                type="radio"
                name="appointmentType"
                value="non-IV"
                checked={appointmentType === 'non-IV'}
                onChange={() => {
                  setAppointmentType('non-IV')
                  setRequiredFields([[
                    'PMHx', 'Meds', 'Allergies', 'BloodPressure', 'HeartRate',
                    'PulseOx', 'StartTime'
                  ]])
                }}
                />
                Non-IV
              </label>
              </td>
            </tr>
            <tr>
              <td><label>List PMHx*:</label></td>
              <td><textarea name="PMHx" required value={medicalData.PMHx} onChange={handleChange}></textarea></td>
            </tr>
            <tr>
              <td><label>List Meds*:</label></td>
              <td><textarea name="Meds" required value={medicalData.Meds} onChange={handleChange}></textarea></td>
            </tr>
            <tr>
              <td><label>Allergies*:</label></td>
              <td><input type="text" name="Allergies" required value={medicalData.Allergies} onChange={handleChange} /></td>
            </tr>
            <tr>
              <td><label>Blood Pressure*:</label></td>
              <td><input type="text" name="BloodPressure" required value={medicalData.BloodPressure} onChange={handleChange} /></td>
            </tr>
            <tr>
              <td><label>Heart Rate*:</label></td>
              <td><input type="text" name="HeartRate" required value={medicalData.HeartRate} onChange={handleChange} /></td>
            </tr>
            <tr>
              <td><label>Pulse Ox*:</label></td>
              <td><input type="text" name="PulseOx" required value={medicalData.PulseOx} onChange={handleChange} /></td>
            </tr>
            {appointmentType === 'IV'
              ? (
            <>
              <tr>
                <td><label>IV Attempts*:</label></td>
                <td>
                  <select name="IVAttempts" required value={medicalData.IVAttempts} onChange={handleChange}>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td><label>IV Placement*:</label></td>
                <td><input type="text" name="IVPlacement" required value={medicalData.IVPlacement} onChange={handleChange} /></td>
              </tr>
              <tr>
                <td><label>IV Gauge*:</label></td>
                <td>
                  <select name="IVGauge" required value={medicalData.IVGauge} onChange={handleChange}>
                    <option value="18g">18g</option>
                    <option value="20g">20g</option>
                    <option value="22g">22g</option>
                    <option value="24g">24g</option>
                    <option value="na">NA</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td><label>Fluid Type*:</label></td>
                <td>
                  <select name="FluidType" required value={medicalData.FluidType} onChange={handleChange}>
                    <option value="NS">NS</option>
                    <option value="LR">LR</option>
                    <option value="NA">Not Applicable</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td><label>Fluid Volume:</label></td>
                <td>
                  <select name="FluidVolume" required value={medicalData.FluidVolume} onChange={handleChange}>
                    <option value="1000">1000 mL</option>
                    <option value="500">500 mL</option>
                    <option value="1500">1500 mL</option>
                    <option value="2000">2000 mL</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td><label>Start Time*:</label></td>
                <td><input type="time" name="StartTime" required value={medicalData.StartTime} onChange={handleChange} /></td>
              </tr>
              <tr>
                <td><label>Stop Time*:</label></td>
                <td><input type="time" name="StopTime" required value={medicalData.StopTime} onChange={handleChange} /></td>
              </tr>
            </>)
              : (<>
                <tr>
                  <td><label>Injection Time*:</label></td>
                  <td><input type="time" name="StartTime" required value={medicalData.StartTime} onChange={handleChange} /></td>
                </tr>
              </>)}
            <tr>
            <td><label>Nurse Notes:</label></td>
            <td>
              {medicalData.NurseNotes.map((note, index) => (
                <div key={index}>
                  <strong>{note.date}:</strong> {note.note}
                </div>
              ))}
              <textarea
                name="NurseNotes"
                value={newNurseNote}
                onChange={handleNurseNoteChange}
                placeholder="Add new note..."
              />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <button onClick={() => openTreeForm(9)}>Add Shot Medical Chart</button>
      <button onClick={() => openTreeForm(5)}>Add Weight Loss Medical Chart</button>
      <Modal isOpen={isTreeModalOpen} onRequestClose={closeTreeModal} style={{ content: { width: '100%', height: '100%' } }}>
        <TreeRendererFromDB startingTreeId={currentTree} closeModal={closeTreeModal} bookingData={bookingData}/>
        <button onClick={closeTreeModal}>Close Form</button>
      </Modal>
      <TreeViewSummary key={summaryKey} bookingUuid={bookingData.uuid} />
    </div>
  );
}

export default MedicalTabContent;


// locations: r thigh, l thigh, r deltoid, l deltoid, r glute, l glute, abdomen
