import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import moment from 'moment';

const TableViewOfManyBooking = (props) => {
  const initialStartDate = moment().startOf('day');
  const navigate = useNavigate(); // Use useNavigate hook
  const { status, cityId } = useParams();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]); // [bookings, setBookings
  const [listOfStaffs, setListOfStaffs] = useState([]);
  const [user, setUser] = useState('');
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(moment().endOf('day'));
  const [selectedStaff, setSelectedStaff] = useState('all');

  const aclLevelRequired = 2; // Must be greater than this level.
  useEffect(() => {
    // Check user's authentication status when component mounts
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setIsAuthenticated(true);
          setUser(data.user)
        } else {
          window.location.href = '/login';
        }
      });
  }, []);

  function formatDate (dateString) {
    const options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    const date = new Date(dateString);
    // return new Intl.DateTimeFormat('en-US', options).format(date).replace(',', ' at');
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  useEffect(() => {
    if (isAuthenticated) {
      const bodyJson = {
        status,
        cityId,
        // convert startDate and endDate to string with only the date part without moment
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      }
      fetch('/api/admin/getPaidBookings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyJson),
      })
        .then(response => response.json())
        .then(data => {
          const sortedData = data.sort((a, b) => {
            const startTimeDifference = moment(b.startTime).diff(moment(a.startTime));
            if (startTimeDifference !== 0) {
              return startTimeDifference;
            }
            if (a.patient.lastName && b.patient.lastName) {
              return a.patient.lastName.localeCompare(b.patient.lastName);
            }
            return 0;
          });
          setBookings(sortedData)
          setFilteredBookings(sortedData)
          const staffList = data.reduce((acc, cv) => {
            if (!acc.find(e => e.id === cv.staff.id)) {
              acc.push({ id: cv.staff.id, firstName: cv.staff.firstName, lastName: cv.staff.lastName })
            }
            return acc
          }, [])
          // sort the staffList by firstName
          staffList.sort((a, b) => a.firstName.localeCompare(b.firstName))
          setListOfStaffs(staffList)
        })
        .catch(error => console.error('Error fetching bookings:', error));
    }
  }, [isAuthenticated, status, cityId, startDate, endDate]);

  if (!isAuthenticated) {
    return <div>Loading...</div>;
  }

  // add a function that when given the booking, finds if extraStuff.payJSON exists and returns the totalPay
  const returnPayInfo = (booking) => {
    if (booking.extraStuff && booking.extraStuff.payJSON) {
      return (
        <div>
          Base Pay: ${booking.extraStuff.payJSON.basePay} <br/>
          Total Travel Fee: ${booking.extraStuff.payJSON.totalTravelFee} <br/>
          Other Payments: ${booking.extraStuff.payJSON.otherPayments} <br/>
          Total Pay: ${booking.extraStuff.payJSON.totalPay} <br/>
          Cash Payments: ${booking.extraStuff.payJSON.cashPayments}
        </div>
    ) }
  }

  const handleStaffChange = (e) => {
    setSelectedStaff(e.target.value)
    // console.log('from TableViewOfManyBooking data', bookings)
    // console.log('from TableViewOfManyBooking data', selectedStaff, e.target.value)
    let sortedData = bookings
    if (e.target.value !== 'all') {
      sortedData = sortedData.filter(booking => booking.primaryStaffId == e.target.value)
    }
    // console.log('from TableViewOfManyBooking sortedData', sortedData)
    // setBookings(sortedData)
    setFilteredBookings(sortedData)
  }

  // create a function that reduces the bookings and ads up the totalPay, cashPayments, basePay, totalTravelFee, otherPayments
  const findTotalPay = () => {

    const totalPay = filteredBookings.reduce((acc, cv) => {
      if (cv.extraStuff && cv.extraStuff.payJSON) {
        return acc + Number(cv.extraStuff.payJSON.totalPay)
      } else {
        return acc
      }
    }, 0)
    const totalBaseIV = filteredBookings.reduce((acc, cv) => {
      if (cv.extraStuff && cv.extraStuff.payJSON) {
        return acc + Number(cv.extraStuff.payJSON.baseIVCount)
      } else {
        return acc
      }
    }, 0)
    const totalTravelFee = filteredBookings.reduce((acc, cv) => {
      if (cv.extraStuff && cv.extraStuff.payJSON) {
        return acc + Number(cv.extraStuff.payJSON.totalTravelFee)
      } else {
        return acc
      }
    }, 0)
    const totalOtherPayments = filteredBookings.reduce((acc, cv) => {
      if (cv.extraStuff && cv.extraStuff.payJSON) {
        return acc + Number(cv.extraStuff.payJSON.otherPayments)
      } else { return acc }
    }, 0)
    const cashPayments = filteredBookings.reduce((acc, cv) => {
      if (cv.extraStuff && cv.extraStuff.payJSON) {
        return acc + Number(cv.extraStuff.payJSON.cashPayments)
      } else { return acc }
    }, 0)
    const totalTips = filteredBookings.reduce((acc, cv) => { 
      return acc + Number(cv.tip)
    }, 0)
    return (
      <div>
        Total Pay: ${Math.round(totalPay*100)/100} <br/>
        Total Travel Fee: ${totalTravelFee} <br/>
        Total Other Payments: ${totalOtherPayments} <br/>
        Total Cash Payments: ${cashPayments} <br/>
        Total Tips: ${totalTips} <br/>
        Total Base IV: {totalBaseIV}
      </div>
    )
  }

  return (
    <div className='adminParent adminTable'>
      <h1>Historical View Of Paid Bookings</h1>
      <p> Pay calculations are in beta.</p>
      {moment(endDate).diff(moment(startDate), 'days') > 60
        ? <span style={{ color: 'red' }}>Start and End dates are more than 60 days apart. Results will be limited.</span>
        : null}
      <div>
        <label htmlFor="startDate">Start Date:</label>
        <input
          type="date"
          id="startDate"
          value={startDate.format('YYYY-MM-DD')}
          onChange={e => setStartDate(moment(e.target.value).startOf('day'))}
        />
        <label htmlFor="endDate"> End Date:</label>
        <input
          type="date"
          id="endDate"
          value={endDate.format('YYYY-MM-DD')}
          onChange={e => setEndDate(moment(e.target.value).endOf('day'))}
        />
        {'   '}
        {(user.acl > aclLevelRequired)
          ? (<span>
            <label htmlFor="staff">Staff:</label>
            <select name="staff" id="staff" onChange={handleStaffChange}>
            <option value="all">All</option>
            {listOfStaffs.map(staff => (
              <option key={staff.id} value={staff.id}>{staff.firstName} {staff.lastName}</option>
            ))}
            </select>
            </span>)
          : '' }
      </div>
      <table>
        <thead>
          <tr>
            <th>Start Time</th>
            {(user.acl > aclLevelRequired ? <th>Staff</th> : null)}
            <th>Paid</th>
            <th>Services Performed</th>
            <th>Tip</th>
            <th>Patient <br/>First Name</th>
            <th>Pay</th>
          </tr>
        </thead>
        <tbody>
          {filteredBookings.map(booking => (
            <tr key={booking.id}>
              <td>{formatDate(booking.startTime)}</td>
              {(user.acl > aclLevelRequired ? <td>{booking.staff.firstName} <br/> {booking.staff.lastName}</td> : null)}
              <td style={{ maxWidth: '50px', overflow: 'auto' }}>
                ${booking.patientPayments.reduce((acc, cv) => { return Number(acc) + Number(cv.amount) }, Number(0))} <br/>
                {(booking.patientPayments.length > 0) ? booking.patientPayments[0].method : null }
              </td>
              <td style={{ maxWidth: '150px', overflow: 'auto' }}>{booking.servicePerformeds.map(service => (
                <span key={service.serviceUuid} value={service.serviceUuid}>
                  {service.service && service.service.name}
                <br/></span>
              ))}
              </td>
              <td>${Math.round(booking.tip * 100)/100}</td>
              <td>{
              // show booking.patient.firstName, but only the first initial
              booking.patient.firstName.charAt(0)
              }</td>
              <td>{returnPayInfo(booking)}</td>
              {(user.acl > aclLevelRequired ? <td><button onClick={() => navigate(`/admin/bookingNew/${booking.uuid}`)}>view</button><br /></td> : null)}
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        {findTotalPay()}
      </div>
      <div className="calendar-bottom-space">
        <button onClick={() => navigate('/admin/byCity/' + cityId)} className="navigate-button">
          Back
        </button>
      </div>
    </div>
  );
}

export default TableViewOfManyBooking;
