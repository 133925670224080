import React, { useState, useEffect } from 'react';
import LogoutButton from './LogoutButton';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const DoctorReview = () => {
  const navigate = useNavigate(); // Use useNavigate hook
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [noteText, setNoteText] = useState('');

  useEffect(() => {
    // Check user's authentication status when component mounts
    fetch('/api/auth/status', {
      credentials: 'include' // necessary to include cookies
    })
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setIsAuthenticated(true);
        } else {
          // If not authenticated, redirect to login
          window.location.href = '/login';
        }
      });
  }, []);

  if (!isAuthenticated) {
    return <div>Not authenticated...</div>;
  }

  const updateBookingInfo = () => {
    const newNote = {
      text: noteText || 'reviewed by doctor',
      date: new Date().toISOString(),
      // Assuming 'loggedInPerson' is available in your context or props
      doctor: 'loggedInPerson',
    };

    // Append new note to existing JSON and update
    const updatedNotes = bookingData.patientAddendumNotes
      ? [...bookingData.patientAddendumNotes, newNote]
      : [newNote];

    fetch('/api/admin/updateBooking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: bookingData.uuid,
        patientAddendumNotes: updatedNotes
      })
        // .then(response => response.json())
        .then(async response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          console.log('from medicalTabContent update booking response', data)
        // const sortedData = data.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
        // setWorkSchedules(sortedData);
        // likely needs to update state such the list of services
        })
    })
  }

  return (
    <div className='adminParent'>
      <h1>Welcome to the Doctor Review Page</h1>
      <button> Previous </button> <button> Next </button>
      <div className='adminTextField'>
        <i>FILL WITH IDENTICALLY TO MEDICAL TAB CONTENT</i>
        <p className='todo'>
          Changes from medical tab <br />
          * no ability to change the information. Only view it.
          * no save button needed<br/>
          * include patent name, birthday, sex, and other info from general<br/>
          * date<br/>
          * services performed<br/>
          * can't say loggedinPerson for the doctor
          * probably best to literally copy the medical info tab (and use it in two places) since it's likely to change <br/>
          * mark complete button can only work if staff.isDoctor == true
        </p>
      </div>
      <div className='adminTextField'>
      <label>
          Medical Addendum Notes: <br/>
          <textarea value={noteText} onChange={(e) => setNoteText(e.target.value)} />
      </label>
      </div>
      <p className='todo'>
        * page only available to ACL =4 or staff.isDoctor==TRUE<br/>
        * totally unsure if any of this works since it wasn't populated with code<br/>
      </p>
      <button onClick={() => updateBookingInfo}>Mark Complete & go to next</button> <br />
      <button onClick={() => navigate('/admin')}>Admin Home</button> <br />
    </div>
  );
}

export default DoctorReview;
