import React, { useState, useEffect } from 'react';
import ServicesTable from './ServicesTable.js';
import Navbar from '../generalComponents/Navbar.js';
import { PaymentForm, CreditCard, GooglePay, ApplePay } from 'react-square-web-payments-sdk';
import { useParams } from 'react-router-dom';
import { formatDate, formatPrice } from '../utility/module.js';
import BraintreeDropIn from './BraintreeDropIn.js';
import Footer from '../generalComponents/footer.js';
import '../adminBooking/PaymentTabContent.css';


const CheckoutMultiple = () => {
  const { multipleBookingUUID, groupPhoneNumber } = useParams();
  const [bookingData, setBookingData] = useState({});
  const [queriedPayments, setQueriedPayments] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [dueAmount, setDueAmount] = useState(0);
  const [tipWasSelected, setTipWasSelected] = useState(0);
  const [customTip, setCustomTip] = useState(false);
  const [customTipInputValue, setCustomTipInputValue] = useState('');
  const [helcimCheckoutToken, setHelcimCheckoutToken] = useState('');
  // const [helcimSecretToken, setHelcimSecretToken] = useState('');
  const [isHelcimScriptLoaded, setIsHelcimScriptLoaded] = useState(false);
  const [isBraintreeScriptLoaded, setIsBraintreeScriptLoaded] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedRecipient, setSelectedRecipient] = useState('');
  const [recipients, setRecipients] = useState([]);

  // load helcim and braintree script
  useEffect(() => {
    const helcimScript = document.createElement('script');
    const braintreeScript1 = document.createElement('script');
    const braintreeScript2 = document.createElement('script');
    const braintreeScript3 = document.createElement('script');
    const braintreeScript4 = document.createElement('script');
    helcimScript.src = 'https://secure.helcim.app/helcim-pay/services/start.js';
    braintreeScript1.src = 'https://js.braintreegateway.com/web/dropin/1.42.0/js/dropin.min.js';
    braintreeScript2.src = 'http://code.jquery.com/jquery-3.2.1.min.js';
    braintreeScript3.src = 'https://js.braintreegateway.com/web/3.99.0/js/client.min.js';
    braintreeScript4.src = 'https://js.braintreegateway.com/web/3.99.0/js/apple-pay.min.js';
    helcimScript.type = 'text/javascript';
    braintreeScript1.type = 'text/javascript';
    braintreeScript2.type = 'text/javascript';
    braintreeScript3.type = 'text/javascript';
    braintreeScript4.type = 'text/javascript';
    braintreeScript2.crossOrigin = 'anonymous';
    helcimScript.onload = () => setIsHelcimScriptLoaded(true); // Set flag when script is loaded
    braintreeScript2.onload = () => setIsBraintreeScriptLoaded(true); // Set flag when script is loaded
    document.body.appendChild(helcimScript);
    document.body.appendChild(braintreeScript1);
    document.body.appendChild(braintreeScript2);
    document.body.appendChild(braintreeScript3);
    document.body.appendChild(braintreeScript4);

    return () => {
      document.querySelectorAll('script[src="https://secure.helcim.app/helcim-pay/services/start.js"]').forEach(el => el.remove());
      document.querySelectorAll('script[src="https://js.braintreegateway.com/web/dropin/1.42.0/js/dropin.min.js"]').forEach(el => el.remove());
      document.querySelectorAll('script[src="https://js.braintreegateway.com/web/3.99.0/js/client.min.js"]').forEach(el => el.remove());
      document.querySelectorAll('script[src="https://js.braintreegateway.com/web/3.99.0/js/apple-pay.min.js"]').forEach(el => el.remove());
      document.querySelectorAll('script[src="http://code.jquery.com/jquery-3.2.1.min.js"]').forEach(el => el.remove());
    };
  }, []);

// Updated useEffect to populate recipients
useEffect(() => {
  if (bookingData && Array.isArray(bookingData) && bookingData.length > 0) {
    const uniqueRecipients = bookingData.reduce((acc, booking) => {
      if (booking && booking.patient) {
        const patient = booking.patient;
        if (!acc.find(r => r.uuid === patient.uuid)) {
          acc.push({
            id: patient.uuid,
            name: `${patient.firstName} ${patient.lastName}`,
            email: patient.email
          });
        }
      }
      return acc;
    }, []);

    setRecipients(uniqueRecipients);
    if (uniqueRecipients.length > 0) {
      setSelectedRecipient(uniqueRecipients[0].id);  // Set the first recipient as default
    }
  }
}, [bookingData]);
  // load booking data
  useEffect(() => {
    fetch('/api/getMultipleBookingForMultipleBookingsUuidPublic/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: multipleBookingUUID,
      })
    })
      .then(response => response.json())
      .then(data => {
        // console.log('setting booking data', data)
        setBookingData(data.plainBooking)
        setQueriedPayments(data.payments)

        // Calculate and set the default tip
        const totalTip = data.reduce((sum, booking) => {
          return sum + parseFloat(booking.tip || 0); // convert tip to cents
        }, 0);

        setTipMultiple(totalTip);
      })
      .catch(error => {
        console.error('Error fetching booking details:', error)
      })
  }, [multipleBookingUUID])

  // calculate total and tip input state
  useEffect(() => {
    // Flatten servicePerformeds from all bookings
    const allServicePerformeds = bookingData[0] && bookingData.flatMap(booking => booking.servicePerformeds || []);

    // Calculate new total
    const newTotal = allServicePerformeds && allServicePerformeds.reduce((sum, service) => {
      console.log('service', service)
      console.log('service.service.price', service.service.price)
      return sum + parseFloat(service.service.price);
    }, 0);
    setTotalAmount(newTotal);

    bookingData[0] && setCustomTipInputValue(bookingData.reduce((acc, booking) => acc + (parseFloat(booking.tip) || 0), 0).toFixed(2))
  }, [bookingData, bookingData[0]]);

  // calculate total amount paid
  useEffect(() => {
    const newTotalPaid = bookingData[0] && bookingData.reduce((totalSum, booking) => {
      const bookingSum = booking.patientPayments.reduce((sum, payment) => {
        return sum + parseFloat(payment.amount || 0);
      }, 0);
      return totalSum + bookingSum;
    }, 0);

    const newDueAmount = totalAmount - newTotalPaid; // in cents
    setPaidAmount(newTotalPaid);
    setDueAmount(newDueAmount);
    bookingData[0] && calculateTotal(
      bookingData.reduce((acc, booking) => acc.concat(booking.servicePerformeds || []), []), // services
      bookingData.reduce((acc, booking) => acc + (parseFloat(booking.tip) || 0), 0), // tips
      bookingData.reduce((acc, booking) => {
        return acc + (parseFloat(booking.discount) || 0);
      }, 0)); // discounts
  }, [bookingData, totalAmount, paymentStatus]);

  useEffect(() => {
    if (tipWasSelected && dueAmount > 0) {
      console.log('is disabled on')
      setIsDisabled(true); // Disable the button

      // Set a timeout to delay the fetch call
      console.log('dueAmount', dueAmount)
      const timeoutId = setTimeout(() => {
        fetch('/api/intializeHelcimCheckout/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            amount: dueAmount,
            cityID: bookingData[0].cityCalendarId
          })
        })
          .then(response => response.json())
          .then(data => {
            // console.log('helcim tokens', data[0])
            setHelcimCheckoutToken(data[0].checkoutToken)
            console.log('is disabled off')
            setIsDisabled(false); // Re-enable the button

          // setHelcimSecretToken(data[0].secretToken)
          })
          .catch(error => {
            console.error('Error fetching booking details:', error)
          });
      }, 1500); // Delay set for 1.5 seconds

      // Cleanup function to clear the timeout
      return () => clearTimeout(timeoutId);
    }
  }, [tipWasSelected, dueAmount]);

  // helcim show iframe
  const handlePaymentClick = async () => {
    // console.log(isDisabled)
    if (isHelcimScriptLoaded && window.appendHelcimPayIframe) {
      // Replace `helcimCheckoutToken` with actual token value
      window.appendHelcimPayIframe(helcimCheckoutToken, true);
    } else {
      console.error('Payment script not loaded yet.');
    }
  };

  // handles nested field changes in bookingData object array
  const handleFieldChange = (field, value) => {
    // console.log('bookingsData', bookingData);
    const keys = field.split('.');

    setBookingData(prevData => {
      return prevData.map(booking => {
      // Clone the booking object
        const updatedBooking = { ...booking };

        // Apply the changes
        keys.reduce((o, k, i) => {
          if (i === keys.length - 1) {
            o[k] = value;
          } else {
            o[k] = o[k] || {};
          }
          return o[k];
        }, updatedBooking);

        return updatedBooking;
      });
    });
  }

  // creates payment request dependency
  useEffect(() => {
    const handlePaymentMessage = async (event) => {
      // console.log(helcimCheckoutToken)
      const helcimPayJsIdentifierKey = 'helcim-pay-js-' + helcimCheckoutToken;

      if (event.data.eventName === helcimPayJsIdentifierKey) {
        if (event.data.eventStatus === 'ABORTED') {
          console.error('Transaction failed!', event.data.eventMessage);
        }

        if (event.data.eventStatus === 'SUCCESS') {
          const amountJson = bookingData.map(booking => {
            const serviceAmount = booking.servicePerformeds.reduce((sum, servicePerformed) => {
              const price = parseFloat(servicePerformed.service.price);
              return sum + (isNaN(price) ? 0 : price * servicePerformed.quantity);
            }, 0);

            const individualTip = parseFloat(booking.tip) || 0;
            const discount = parseFloat(booking.discount) || 0;
            const totalAmount = serviceAmount + individualTip - discount;

            return {
              bookingUuid: booking.uuid,
              amount: totalAmount,
              tip: Number(individualTip)
            };
          });
          const helcimResponse = JSON.parse(event.data.eventMessage);
          console.log('Transaction success!', helcimResponse);
          // console.log('Transaction success! data', event.data);
          // console.log('Transaction success! event', event);
          // console.log("bookingData",bookingData)
          const selectedRecipientData = recipients.find(r => r.id === selectedRecipient);
          const paymentResponse = await fetch('/api/processMultiplePayments', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              processorId: helcimResponse.data.data.transactionId,
              amount: Number(dueAmount),
              multipleBookingUuid: multipleBookingUUID,
              note: '',
              method: 'helcim',
              groupPhoneNumber,
              extraStuff: helcimResponse.data.data,
              amountJson,
              recipientEmail: selectedRecipientData.email,
              recipientName: selectedRecipientData.name,
              cityCalendarId: bookingData[0].cityCalendarId
            })
          });
          if (paymentResponse.status === 200) {
            setPaymentStatus('Payment succeeded');
            // const updatedBookingData = bookingData.map(booking => {
            //   const correspondingAmountJson = amountJson.find(a => a.bookingUuid === booking.uuid);

            //   // Check if patientPayments is an array, and add the new record
            //   const updatedPatientPayments = Array.isArray(booking.patientPayments)
            //     ? [...booking.patientPayments, { ...paymentResponse.newRecord, amount: Number(correspondingAmountJson.amount) }]
            //     : [{ ...paymentResponse.newRecord, amount: Number(correspondingAmountJson.amount) }];

            //   // Update the patientPayments and patientPaid fields for each booking
            //   handleFieldChange(`${booking.uuid}.patientPayments`, updatedPatientPayments);
            //   handleFieldChange(`${booking.uuid}.patientPaid`, true);

            //   // Return the updated booking object
            //   return {
            //     ...booking,
            //     patientPayments: updatedPatientPayments,
            //     patientPaid: true
            //   };
            // });
            // setBookingData(updatedBookingData);

            window.location.reload();
          } else {
            setPaymentStatus('Payment failed');
            // squareButton.classList.add('red');
            // console.log(squareButton)
            const style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML = `
      .c-jWYnUm {
        background-color: red !important;
        color: white !important;
      }
    `;
            document.head.appendChild(style);
          }
        }
      }
    };
    // Add event listener
    window.addEventListener('message', handlePaymentMessage);

    // Cleanup function
    return () => {
      window.removeEventListener('message', handlePaymentMessage);
    };
  }, [helcimCheckoutToken]);

  const processBraintreeTransaction = async (nonce, setIsBraintreeDisabled) => {
    try {
      const amountJson = bookingData.map(booking => {
        const serviceAmount = booking.servicePerformeds.reduce((sum, servicePerformed) => {
          const price = parseFloat(servicePerformed.service.price);
          return sum + (isNaN(price) ? 0 : price * servicePerformed.quantity);
        }, 0);

        const individualTip = parseFloat(booking.tip) || 0;
        const discount = parseFloat(booking.discount) || 0;
        const totalAmount = serviceAmount + individualTip - discount;

        return {
          bookingUuid: booking.uuid,
          amount: totalAmount,
          tip: Number(individualTip)
        };
      });
      setIsBraintreeDisabled(true)
      setIsDisabled(true)
      // console.log("bookingData",bookingData)
      const selectedRecipientData = recipients.find(r => r.id === selectedRecipient);
      const response = await fetch('/api/processMultiplePayments', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: Number(dueAmount),
          multipleBookingUuid: multipleBookingUUID,
          amountJson,
          note: '',
          method: 'braintree',
          groupPhoneNumber,
          processor: 'braintree',
          processorId: nonce,
          recipientEmail: selectedRecipientData.email,
          recipientName: selectedRecipientData.name,
          cityCalendarId: bookingData[0].cityCalendarId
        })
      });
      const paymentResponse = await response.json();
      // console.log('paymentResponse:', paymentResponse)
      if (paymentResponse.newRecord) {
        // console.log(paymentResponse)
        setPaymentStatus('Payment successful');
        // const updatedServices = [...bookingData.patientPayments, paymentResponse.newRecord];
        // squareButton.classList.add('green');
        // setTimeout(() => squareButton.classList.remove('green'), 3000);
        // handleFieldChange('patientPayments', updatedServices);
        // handleFieldChange('patientPaid', true);
        window.location.reload();
      } else {
        setPaymentStatus('Payment failed');
        setIsBraintreeDisabled(false)
        setIsDisabled(false)
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      setPaymentStatus('Error processing payment');
      //       squareButton.style.backgroundColor = 'red';
      //       const style = document.createElement('style');
      //       style.type = 'text/css';
      //       style.innerHTML = `
      // .c-jWYnUm {
      //   background-color: red !important;
      //   color: white !important;
      // }
      // `;
      // document.head.appendChild(style);
    }
  }

  // handle CC payment submission
  const handleCCPayment = async (paymentResult) => {
    const squareButton = document.getElementById('rswp-card-button');

    if (paymentResult.token) {
      try {
        const amountJson = bookingData.map(booking => {
          const serviceAmount = booking.servicePerformeds.reduce((sum, servicePerformed) => {
            const price = parseFloat(servicePerformed.service.price);
            return sum + (isNaN(price) ? 0 : price * servicePerformed.quantity);
          }, 0);

          const individualTip = parseFloat(booking.tip) || 0;
          const discount = parseFloat(booking.discount) || 0;
          const totalAmount = serviceAmount + individualTip - discount;

          return {
            bookingUuid: booking.uuid,
            amount: totalAmount,
            tip: Number(individualTip)
          };
        });
        const selectedRecipientData = recipients.find(r => r.id === selectedRecipient);
        // console.log(selectedRecipientData)
        const response = await fetch('/api/processMultiplePayments', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            nonce: paymentResult.token,
            totalAmount: Number(dueAmount),
            multipleBookingUuid: multipleBookingUUID,
            amountJson,
            note: '',
            method: 'square',
            groupPhoneNumber,
            recipientEmail: selectedRecipientData.email,
            recipientName: selectedRecipientData.name,
            cityCalendarId: bookingData[0].cityCalendarId
          })
        });

        const paymentResponse = await response.json();
        // console.log(paymentResponse, 'here')
        if (paymentResponse.newRecord) {
          setPaymentStatus('Payment succeeded');
          const updatedBookingData = bookingData.map(booking => {
            const correspondingAmountJson = amountJson.find(a => a.bookingUuid === booking.uuid);

            // Check if patientPayments is an array, and add the new record
            const updatedPatientPayments = Array.isArray(booking.patientPayments)
              ? [...booking.patientPayments, { ...paymentResponse.newRecord, amount: Number(correspondingAmountJson.amount) }]
              : [{ ...paymentResponse.newRecord, amount: Number(correspondingAmountJson.amount) }];

            // Update the patientPayments and patientPaid fields for each booking
            handleFieldChange(`${booking.uuid}.patientPayments`, updatedPatientPayments);
            handleFieldChange(`${booking.uuid}.patientPaid`, true);

            // Return the updated booking object
            return {
              ...booking,
              patientPayments: updatedPatientPayments,
              patientPaid: true
            };
          });
          setBookingData(updatedBookingData);

          window.location.reload();
        } else {
          setPaymentStatus('Payment failed');
          squareButton.classList.add('red');
          // console.log(squareButton)
          const style = document.createElement('style');
          style.type = 'text/css';
          style.innerHTML = `
    .c-jWYnUm {
      background-color: red !important;
      color: white !important;
    }
  `;
          document.head.appendChild(style);
        }
      } catch (error) {
        console.error('Error processing payment:', error);
        setPaymentStatus('Error processing payment');
        // console.log(squareButton)
        squareButton.style.backgroundColor = 'red';
        const style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = `
  .c-jWYnUm {
    background-color: red !important;
    color: white !important;
  }
`;
        document.head.appendChild(style);
      }
    }
  };

  // set tip equally across booking objects
  const setTipMultiple = (value) => handleFieldChange('tip', (value / (bookingData.length)))

  // apply tip button handling
  const applyTip = (percentage) => {
    setTipWasSelected(true)
    const servicesTotal = bookingData.reduce((acc, booking) => acc.concat(booking.servicePerformeds || []), []).reduce((sum, item) => sum + parseFloat(item.service.price) * item.quantity, 0);
    const tipValue = servicesTotal * (percentage / 100);
    setTipMultiple(Number(tipValue));
  };

  // enter listener for custom tip
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleCustomTipBlur();
      e.target.blur();
    }
  };

  const handleCustomTipBlur = () => {
    setTipWasSelected(true);
    setCustomTipInputValue(parseFloat(customTipInputValue).toFixed(2))
    setTipMultiple(parseFloat(customTipInputValue).toFixed(2));
  };

  // select entire input on selection
  const handleFocus = (event) => {
    event.target.select();
  };

  const handleCustomTipChange = (e) => {
    setTipWasSelected(true);
    setCustomTipInputValue(e.target.value); // Directly update with input value
  };

  const calculateTotal = (services, tipAmount, discountAmount) => {
    const servicesTotal = services.reduce((sum, item) => sum + parseFloat(item.service.price) * item.quantity, 0);
    const tipValue = parseFloat(tipAmount);
    const discountValue = parseFloat(discountAmount);
    const total = servicesTotal + (!isNaN(tipValue) ? tipValue : 0) - (!isNaN(discountValue) ? discountValue : 0);
    const newDue = total - paidAmount;
    setTotalAmount(total);
    setDueAmount(newDue);
    return total;
  };

  return (
    <>
    <Navbar/>
    <div className='payment-container'>
    <h1 style={{ textAlign: 'center' }}>Checkout</h1>
    <div className='services-table'>
      {console.log('paid amount', paidAmount)}
    <table>
      <thead>
        <tr>
          <th>Service</th>
          <th>Amount</th>
          <th>Quantity</th>
        </tr>
      </thead>
      <tbody>
        {bookingData[0] && bookingData.reduce((acc, booking) => acc.concat(booking.servicePerformeds || []), []).map((servicePerformed) => (
          <tr key={servicePerformed.uuid}>
          {/* {console.log('servicePerformed', servicePerformed)} */}
            <td>{servicePerformed.service.name}</td>
            <td>${formatPrice(servicePerformed.service.price)}</td> {/* convert to cents for consistency */}
            <td>{servicePerformed.quantity}</td>
          </tr>
        ))}
        <tr>
            <td colSpan="2" className='total-label'>Tip</td>
            <td className='total-value'>
              <div>${bookingData[0] && formatPrice(bookingData.reduce((acc, booking) => acc + (parseFloat(booking.tip) || 0), 0))}</div>
            </td>
          </tr>
        <tr>
            <td colSpan="2" className='total-label'>Discount</td>
            <td className='total-value'>
            $<input
              type="text"
              value={bookingData[0] && formatPrice(bookingData.reduce((acc, booking) => {
                return acc + (parseFloat(booking.discount) || 0);
              }, 0))}
              disabled
              onFocus={handleFocus}
              className='discount-input'
              style={{ maxWidth: '60px' }}
            />
            </td>
          </tr>
          <tr>
            <td colSpan="2" className='total-label'>Total</td>
            <td className='total-value'>${formatPrice(totalAmount)}</td>
          </tr>
          <tr>
            <td colSpan="2" className='total-label'>Paid</td>
            <td className='total-value'>${formatPrice(paidAmount)}</td>
          </tr>
          <tr>
            <td colSpan="2" className='total-label'>Due</td>
            <td className='total-value'>${formatPrice(dueAmount)}</td>
          </tr>
      </tbody>
    </table>
    {dueAmount > 0.49 && <>
        <table>
        <thead>
          <tr>
            <th colSpan="6">Tip Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="2"><button className="tip-button" onClick={() => applyTip(20)}>20%</button></td>
            <td colSpan="2"><button className="tip-button" onClick={() => applyTip(25)}>25%</button></td>
            <td colSpan="2"><button className="tip-button" onClick={() => applyTip(30)}>30%</button></td>
          </tr>
          <tr>
            <td colSpan="3">
              <button className="tip-button" onClick={() => { setTipWasSelected(true); setTipMultiple('0.00'); }}>No Tip</button>
            </td>
            <td colSpan="3">
              {customTip
                ? (
                  <>Tip Amount: $<input
        type="text"
        value={(customTipInputValue)}
        onChange={handleCustomTipChange}
        onBlur={handleCustomTipBlur}
        onKeyDown={handleKeyDown}
        className='tip-input'
        onFocus={handleFocus}
      /></>
                  )
                : (
                  <button className="tip-button" onClick={() => setCustomTip(true)}>Custom</button>
                  )}
            </td>
          </tr>
        </tbody>
      </table>
      <br/>
                  </>}
  </div>
    {paymentStatus && <p className='payment-status'>{paymentStatus}</p>}

    {dueAmount > 0.49 && tipWasSelected === true && (
          <div className='receipt-selection'>
            <label htmlFor="receipt-recipient">Select recipient for receipt: </label>
            <select
  id="receipt-recipient"
  value={selectedRecipient}
  onChange={(e) => {console.log(selectedRecipient, e);setSelectedRecipient(e.target.value)}}
  required
>
  <option value="">Select a recipient</option>
  {recipients.map((recipient) => (
    <option key={recipient.id} value={recipient.id}>
      {recipient.name} ({recipient.email})
    </option>
  ))}
</select>
          </div>
        )}
        <br/>
    {dueAmount > 0.49 && tipWasSelected === true && 
    <div className=''>
    {bookingData[0].cityCalendar.extraStuff.helcim  && <><button
  style={{
    backgroundColor: isDisabled ? '#cccccc' : '#0F9E82', // Greyed out when disabled
    color: isDisabled ? '#666666' : 'white', // Darker text when disabled
    padding: '10px 20px', // Padding: 10px top and bottom, 20px left and right
    fontSize: '16px', // Font size
    border: 'none', // No border
    borderRadius: '5px', // Rounded corners
    cursor: 'pointer', // Cursor changes to a pointer on hover
    outline: 'none', // Remove outline on focus (for accessibility, consider keeping it or styling it appropriately)
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' // Subtle shadow for depth
  }}
  className="tip-button"
  disabled={isDisabled} // Button is disabled based on isDisabled state
  // disabled// Button is disabled based on isDisabled state
  onClick={handlePaymentClick}
>
  Pay With Card
</button><br/></>}

{bookingData[0].cityCalendar.extraStuff.braintree  && <BraintreeDropIn show={true} onPaymentCompleted={processBraintreeTransaction} setIsDisabled={setIsDisabled} dueAmount={dueAmount}/>}
{paymentStatus && paymentStatus}

      {/* <PaymentForm
        applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID}
        locationId="LB0XPMNHK4RTY"
        createPaymentRequest={createPaymentRequest}
        cardTokenizeResponseReceived={handleCCPayment}
      >
        <fieldset className="sq-fieldset">
          <CreditCard />
          <ApplePay />
        </fieldset>
      </PaymentForm> */}
    </div>
    }
    {console.log('bookingData[0]', bookingData)}
    {dueAmount < 0.49 && bookingData[0] && queriedPayments && queriedPayments.length > 0 && (
      <div style={{
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '20px',
        color: 'black',
        padding: '20px',
        // backgroundColor: '#f0f0f0',
        // borderRadius: '10px',
        margin: '20px',
        lineHeight: '1.2'
        // boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
      }}>
  <div>
    Status: Paid
  </div>
  <div>Date: {formatDate(queriedPayments[0].createdAt)} </div>
  <div>
    Card: ****{queriedPayments[1].last4}
  </div>
  <br/>
  <div style={{ fontSize: '125%' }}>
    Thank you for choosing Luxe Mobile IV!
  </div>
</div>

    )}
  </div>
  <Footer/>
  </>
  )
}

export default CheckoutMultiple;
