// src/components/BookingStatusToggle.jsx
import React from 'react';

const BookingStatusToggle = ({ localBookingData, handleToggleChangeBoolean, handleSave, saveSuccess }) => {
  return (
    <div className="adminTextField">
      <h3>Change Booking Status</h3>
      <label>
        Customer Service Pending:
        <input
          type="checkbox"
          checked={localBookingData.customerServiceIssuePending}
          onChange={() => handleToggleChangeBoolean('customerServiceIssuePending')}
        />
        <span className="slider"></span>
      </label>
      <br />
      <button onClick={handleSave} className={saveSuccess ? 'green' : ''} type="button">
        Save Status Change
      </button>
    </div>
  );
};

export default BookingStatusToggle;