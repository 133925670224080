import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';

const TableViewOfManyBooking = (props) => {
  const navigate = useNavigate();
  const { cityId } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('phone'); // default search type
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [user, setUser] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchSubmitted, setSearchSubmitted] = useState(false);

  useEffect(() => {
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setIsAuthenticated(true);
          setUser(data.user);
        } else {
          window.location.href = '/login';
        }
      });
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value);
  };

  function formatDate (dateString) {
    const options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  const handleSearchSubmit = () => {
    setSearchSubmitted(true);

    const bodyJson = {
      searchTerm,
      searchType,
      cityId,
    };

    fetch('/api/admin/searchBookings', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyJson)
    })
      .then(response => response.json())
      .then(data => {
        const sortedData = data.sort((a, b) => {
          const startTimeDifference = new Date(b.startTime) - new Date(a.startTime);
          if (startTimeDifference !== 0) {
            return startTimeDifference;
          }
          if (a.patient.lastName && b.patient.lastName) {
            return a.patient.lastName.localeCompare(b.patient.lastName);
          }
          return 0;
        })
        setBookings(sortedData)
      })
      .catch(error => console.error('Error fetching bookings:', error));
  };

  if (!isAuthenticated) {
    return <div>Loading...</div>;
  }

  return (
    <div className="adminParent adminTable">
      <h1>Search Bookings</h1>

      <div className="search-controls">

        <label htmlFor="search">Search: </label>
        <input type="text" id="search" value={searchTerm} onChange={handleSearchChange} />

        <select value={searchType} onChange={handleSearchTypeChange}>
        <option value="name">Phone</option>
          <option value="address1">Address</option>
          <option value="lastName">Last Name</option>
        </select>

        <button onClick={handleSearchSubmit}>Search</button>
      </div>
      {searchSubmitted
        ? (
            bookings.length > 0
              ? (
          <table>
            <thead>
              <tr>
                <th>Start Time</th>
                {user.acl > 2 && <th>Staff Name</th>}
                <th>Payments</th>
                <th>Services Performed</th>
                <th>Patient Name</th>
                <th>Status</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {bookings.map(booking => (
                <tr key={booking.id}>
                  <td>{formatDate(booking.startTime)}</td>
                  {user.acl > 2 && (
                    <td>
                      {booking.staff.firstName} {booking.staff.lastName}
                    </td>
                  )}
                  <td>
                    ${booking.patientPayments.reduce((acc, cv) => Number(acc) + Number(cv.amount), 0)}{' '}
                    <br />
                    {booking.patientPayments.length > 0 ? booking.patientPayments[0].method : null}
                  </td>
                  <td>
                    {booking.servicePerformeds.map(service => (
                      <span key={service.uuid} value={service.serviceUuid}>
                        {service.service && service.service.name}
                        <br />
                      </span>
                    ))}
                  </td>
                  <td>
                    {booking.patient.firstName} {booking.patient.lastName}
                  </td>
                  <td>{booking.status}</td>
                  <td>
                    <a
                      href={`/admin/bookingNew/${booking.uuid}`}
                      className="button" 
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`/admin/bookingNew/${booking.uuid}`);
                      }}
                    >
                      <button> view </button>
                    </a>
                    <br />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
                )
              : (
          <p>No bookings found matching your search.</p>
                )
          )
        : (
        <p>Enter a search query and click 'Search' to load bookings. Requires at least 4 characters to search.</p>
          )}
      <div className="calendar-bottom-space">
        <button onClick={() => navigate(`/admin/byCity/${cityId}`)} className="navigate-button">
          Back
        </button>
      </div>
    </div>
  );
};

export default TableViewOfManyBooking;
