import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import './adminStyles.css';

const DynamicScheduleView = () => {
  const [schedules, setSchedules] = useState([]);
  const [filteredSchedules, setFilteredSchedules] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [selectedNurse, setSelectedNurse] = useState('');
  const [nurses, setNurses] = useState([]);
  const { cityId } = useParams();

  useEffect(() => {
    fetchSchedules();
  }, [startDate, endDate]);

  useEffect(() => {
    if (selectedNurse) {
      setFilteredSchedules(schedules.filter(schedule => schedule.nurseName === selectedNurse));
    } else {
      setFilteredSchedules(schedules);
    }
  }, [selectedNurse, schedules]);

  const fetchSchedules = async () => {
    try {
      const response = await fetch('/api/admin/getWorkScheduleOverviewRange', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          startDate,
          endDate,
          cityId,
        }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setSchedules(data);
      
      // Extract unique nurse names
      const uniqueNurses = [...new Set(data.map(schedule => schedule.nurseName))];
      setNurses(uniqueNurses);
    } catch (error) {
      console.error('Error fetching schedules:', error);
    }
  };

  const calculateDuration = (start, end) => {
    const duration = moment.duration(moment(end).diff(moment(start)));
    return `${duration.hours()}h ${duration.minutes()}m`;
  };

  const calculateTotal = () => {
    return filteredSchedules.reduce((total, schedule) => {
      const duration = moment.duration(moment(schedule.endTime).diff(moment(schedule.startTime)));
      return total + duration.asHours();
    }, 0).toFixed(2);
  };

  const generatePivotTable = () => {
    const pivotData = filteredSchedules.reduce((acc, schedule) => {
      if (!acc[schedule?.nurseName]) {
        acc[schedule?.nurseName] = { shifts: 0, hours: 0 };
      }
      acc[schedule?.nurseName].shifts += 1;
      acc[schedule?.nurseName].hours += moment.duration(moment(schedule.endTime).diff(moment(schedule.startTime))).asHours();
      return acc;
    }, {});

    return (
      <table className="pivot-table">
        <thead>
          <tr>
            <th>Nurse</th>
            <th>Total Shifts</th>
            <th>Total Hours</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(pivotData).map(([nurse, data]) => (
            <tr key={nurse}>
              <td>{nurse}</td>
              <td>{data.shifts}</td>
              <td>{data.hours.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="adminParent adminTable">
      <h2>Quick Schedule View</h2>
      <div className="filters">
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <select
          value={selectedNurse}
          onChange={(e) => setSelectedNurse(e.target.value)}
        >
          <option value="">All Nurses</option>
          {nurses.map((nurse) => (
            <option key={nurse} value={nurse}>
              {nurse}
            </option>
          ))}
        </select>
        <br />
        <p className="todo">Results may be limited if requesting more than 30 days</p>
      </div>
      <table className="">
        <thead>
          <tr>
            <th>Start</th>
            <th>End</th>
            <th>Duration</th>
            <th>Nurse</th>
          </tr>
        </thead>
        <tbody>
          {filteredSchedules.map((schedule) => (
            <tr key={schedule.id}>
              <td>{moment(schedule.startTime).format('YYYY-MM-DD HH:mm')}</td>
              <td>{moment(schedule.endTime).format('YYYY-MM-DD HH:mm')}</td>
              <td>{calculateDuration(schedule.startTime, schedule.endTime)}</td>
              <td>{schedule?.nurseName}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="2">Total Hours:</td>
            <td colSpan="2">{calculateTotal()}</td>
          </tr>
        </tfoot>
      </table>
      <h3>Pivot Table</h3>
      {generatePivotTable()}
      <button onClick={() => window.history.back()}>Back</button>
    </div>
  );
};

export default DynamicScheduleView;