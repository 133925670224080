// src/components/CalendarBlockDuration.jsx
import React, { useState } from 'react';
import moment from 'moment';
import { updateCalendarBlock as updateCalendarBlockApi } from '../../api/bookingApi';

const CalendarBlockDuration = ({ localBookingData }) => {
  const [travelTime, setTravelTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const button = e.target;
    const newStartTime = moment(localBookingData.startTime)
      .subtract(travelTime, 'minute')
      .toISOString();
    const newEndTime = moment(newStartTime).add(duration, 'minute').toISOString();
    try {
      await updateCalendarBlockApi({
        uuid: localBookingData.calendarBlockUuid,
        startTime: newStartTime,
        endTime: newEndTime,
      });
      button.classList.add('green');
      setTimeout(() => {
        button.classList.remove('green');
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error('Error updating calendar block:', error);
    }
  };

  return (
    <div className="adminTextField">
      <h3>Change Calendar Block Duration</h3>
      <form onSubmit={handleSubmit}>
        <label>
          Add time before appointment (minutes):{' '}
          <select onChange={(e) => setTravelTime(Number(e.target.value))} value={travelTime} required>
            {[...Array(10)].map((_, index) => (
              <option key={index} value={index * 15}>
                {index * 15} minutes
              </option>
            ))}
          </select>
        </label>
        <br />
        <label>
          Duration (minutes):{' '}
          <select onChange={(e) => setDuration(Number(e.target.value))} value={duration} required>
            {[...Array(12)].map((_, index) => (
              <option key={index} value={(index + 1) * 15}>
                {(index + 1) * 15} minutes
              </option>
            ))}
          </select>
        </label>
        <br />
        <button type="submit">Submit Time Change</button>
      </form>
    </div>
  );
};

export default CalendarBlockDuration;