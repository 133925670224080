// src/components/AdminNotes.jsx
import React, { useState } from 'react';
import { updateBooking as updateBookingApi } from '../../api/bookingApi.js';
import { formatDate } from '../../utility/module.js';

const AdminNotes = ({ user, localBookingData }) => {
  const [adminNotes, setAdminNotes] = useState(localBookingData.adminNotes || []);
  const [adminNoteText, setAdminNoteText] = useState('');

  const handleAdminNoteSubmit = async (e) => {
    e.preventDefault();
    const newAdminNote = {
      date: new Date().toISOString(),
      text: adminNoteText,
      author: user.email,
    };
    const updatedAdminNotes = [...adminNotes, newAdminNote];
    try {
      await updateBookingApi({
        uuid: localBookingData.uuid,
        adminNotes: updatedAdminNotes,
      });
      setAdminNotes(updatedAdminNotes);
      setAdminNoteText('');
    } catch (error) {
      console.error('Error updating admin notes:', error);
    }
  };

  if (user.acl <= 2) {
    return null;
  }

  return (
    <div className="adminTextField">
      <h3>Admin Notes</h3>
      <p>Only viewable to admins. Use for notes that are not customer service issues.</p>
      {adminNotes.length > 0 && (
        <div className="adminTextField">
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Note</th>
                <th>Author</th>
              </tr>
            </thead>
            <tbody>
              {adminNotes.map((note, index) => (
                <tr key={index}>
                  <td>{formatDate(note.date)}</td>
                  <td>{note.text}</td>
                  <td>{note.author}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <form onSubmit={handleAdminNoteSubmit}>
        <textarea
          value={adminNoteText}
          onChange={(e) => setAdminNoteText(e.target.value)}
          rows={4}
          placeholder="Add a new admin note..."
          required
        />
        <br />
        <button type="submit">Save Admin Note</button>
      </form>
    </div>
  );
};

export default AdminNotes;
