// src/components/UpdatePatientInfo.jsx
import React from 'react';

const UpdatePatientInfo = ({ localBookingData, patientNotes, setPatientNotes, updatePatientInfo, saveSuccess, handleToggleChangeBoolean }) => {
  return (
    <div className="adminTextField">
      <h3>Update Patient Info</h3>
      <i>This info is tied to the patient's record and will be attached to future bookings.</i> <br />
      <label>
        VIP Customer:
        <input
          type="checkbox"
          checked={localBookingData.patient.isVIP}
          onChange={() => handleToggleChangeBoolean('patient.isVIP')}
        />
        <span className="slider"></span>
      </label>
      <br />
      <label>
        Blacklist Customer:
        <input
          type="checkbox"
          checked={localBookingData.patient.blackList}
          onChange={() => handleToggleChangeBoolean('patient.blackList')}
        />
        <span className="slider"></span>
      </label>
      <br />
      <label>
        Patient Notes:
        <br />
        <textarea
          value={patientNotes}
          onChange={(e) => setPatientNotes(e.target.value)}
          style={{ width: '90%' }}
          rows={2}
        />
      </label>
      <br />
      <button onClick={updatePatientInfo} className={saveSuccess ? 'green' : ''} type="button">
        Save Patient Info
      </button>
    </div>
  );
};

export default UpdatePatientInfo;