const state = {
  node: {
    type: 'Content',
    treeId: 10,
    node: 1,
    is_root: '0',
    node_name: '',
    page_title: 'Disclaimer',
    content:
    `<div>
    <h2>NAD Injection Renewal</h2>
    <p>Thanks for choosing Luxe Mobile IV for your NAD injections.</p>
    </div>
`,
    question: '',
    x: '510',
    y: '259',
    jsmessage: '',
    messages: {},
    buttons: {
      0: {
        op: '',
        rank: '1',
        value: '0',
        updated: '0',
        hover_text: '',
        button_data: '0',
        button_link: '11',
        button_text: 'Get Started',
        wizard_skip: '0',
        project_node_id: '2',
        logic_expression: ''
      },
    },
  },
  formData: {},
  formFields: {},
  nodesToGoBack: 1,
  renderedContent: '',
  renderedButtons: '',
  renderedForm: '',
  sessionId: '',
};

module.exports = state;