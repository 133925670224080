// src/api/bookingApi.js
export async function getNurses () {
  const response = await fetch('/api/admin/getStaff', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ role: 'nurse' }),
  });
  if (!response.ok) {
    throw new Error('Failed to fetch nurses');
  }
  return response.json();
}

export async function getCities () {
  const response = await fetch('/api/admin/getCityCalendars', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch cities');
  }
  return response.json();
}

export async function updateBooking (updatedData) {
  const response = await fetch('/api/admin/updateBooking', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(updatedData),
  });
  if (!response.ok) {
    throw new Error('Failed to update booking');
  }
  return response.json();
}

export async function cancelBooking (data) {
  const response = await fetch('/api/admin/cancelBooking', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error('Failed to cancel booking');
  }
  return response.json();
}

export async function moveBooking (data) {
  const response = await fetch('/api/admin/moveBooking', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error('Failed to move booking');
  }
  return response.json();
}

export async function updateCalendarBlock (data) {
  const response = await fetch('/api/admin/updateCalendarBlock', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error('Failed to update calendar block');
  }
  return response.json();
}

export async function updatePatientInfo (data) {
  const response = await fetch('/api/admin/updatePatient', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error('Failed to update patient info');
  }
  return response.json();
}
