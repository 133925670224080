// NewBookingForm.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment-timezone';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const NewBookingForm = () => {
  const navigate = useNavigate()
  const { cityId } = useParams()
  const [searchParams] = useState(new URLSearchParams(window.location.search));

  const [formData, setFormData] = useState({
    customerNotes: '',
    firstName: '',
    // middleName: '',
    lastName: '',
    // sex: '',
    // dob: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    email: '',
    zip: '',
    selectedServices: [],
    endTime: '',
    startTime: searchParams.get('startTime') || ''
  });
  const [travelFee, setTravelFee] = useState(null);
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedNurse, setSelectedNurse] = useState(searchParams.get('nurse') || 1);
  const [nurses, setNurses] = useState([]);
  const [filteredNurses, setFilteredNurses] = useState([]);
  const [showPrimaryNursesOnly, setShowPrimaryNursesOnly] = useState(true);
  const [patientsFromPhone, setPatientsFromPhone] = useState([]);
  const [phoneSearch, setPhoneSearch] = useState('')
  const [isFormValid, setIsFormValid] = useState(false);
  const [showListOfAvailableNurses, setShowListOfAvailableNurses] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);

  useEffect(() => {
    // Function to check if all required fields are filled
    const checkFormValidity = () => {
      const requiredFields = [
        formData.firstName,
        formData.lastName,
        formData.phone,
        formData.startTime,
        formData.zip,
        selectedServices,
        selectedNurse
      ];

      // Form is valid only if none of the required fields are empty
      return requiredFields.every(field => field);
    };

    // Update the form validity state
    setIsFormValid(checkFormValidity());
  }, [formData, selectedServices, selectedNurse]);

  const [user, setUser] = useState('');

  useEffect(() => {
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated && data.user.acl >= 3) {
          setUser(data.user)
        } else {
          window.location.href = '/login';
        }
      });
  }, []);

  const getNurses = async () => {
    return fetch('/api/admin/getStaff', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        role: 'nurse',
      })
    })
      .then(response => response.json())
  };
  const fetchNursesAndCities = async () => {
    const fetchedNurses = await getNurses();
    setNurses(fetchedNurses);
  };

  useEffect(() => {
    fetchNursesAndCities();
  }, [])

  const fetchTravelFee = async (zipCode) => {
    try {
      const response = await fetch('/api/admin/getTravelFee', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ zipCode })
      });
      const data = await response.json();
      if (response.ok) {
        setTravelFee(data.travelFee);
        alert(`Travel Fee: ${data.travelFee}`);
      } else {
        setTravelFee(null);
        alert('No travel fee found for this zip code.');
      }
    } catch (error) {
      console.error('Error fetching travel fee:', error);
    }
  };

  const checkNurseAvailability = async (startTime, endTime) => {
    const response = await fetch('/api/outputTimeSlots', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dateToCheck: new Date(startTime).toISOString(),
        cityCalendar: Number(cityId),
        serviceUuid: selectedServices
      })
    }).then(response => response.json()).then(data => {
      // console.log("timeSlots", data.timeSlots)
      setTimeSlots(data.timeSlots)
    })
  }

  const handleChange = async (e) => {
    if (e.target.name === 'selectedServices') {
      const selectedServiceUUID = e.target.value;
      // setFormData({ ...formData, [e.target.name]: e.target.value });
      setSelectedServices(selectedServiceUUID)
      const selectedService = services.find(service => service.uuid === selectedServiceUUID);
      if (formData.startTime && selectedService) {
        calculateEndTime(formData.startTime, selectedService);
      }
    } else if (e.target.name === 'startTime') {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      const selectedService = services.find(service => service.uuid === selectedServices);
      if (selectedService) {
        calculateEndTime(e.target.value, selectedService);
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    async function getPatientsFromPhone () {
      try {
        const response = await fetch('/api/admin/searchPatientsByPhone', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            phone: phoneSearch
          })
        });
        const data = await response.json();
        // console.log("response from patient search", data)
        setPatientsFromPhone(data);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    }
    getPatientsFromPhone()
  }, [phoneSearch])

  const handleBlur = (e) => {
    if (e.target.name === 'zip') {
      fetchTravelFee(e.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    const patientInfo = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone.replace(/\D/g, ''),
      address1: formData.address1,
      address2: formData.address2,
      city: formData.city,
      state: formData.state,
      zipcode: formData.zip,
      // bookingNote: formData.customerNotes,
    };

    const startTime = moment.tz(formData.startTime, 'America/Chicago');
    const bookingNotes = formData.customerNotes;
    const serviceUuid = selectedServices
    const staffId = selectedNurse;
    const loggedInUserId = user.id; // Replace with actual loggedInUserId

    const cookieInfo = {
      loggedInUserId,
      source: 'staffManual',
    };

    try {
      const response = await fetch('/api/admin/createBookingByStartTime', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userInfo: patientInfo,
          startTime,
          bookingNotes,
          serviceUuid,
          cityId,
          staffId,
          cookieInfo,
        })
      });
      const data = await response;
      // if response is 200, then we can clear the form
      if (data.status === 200) {
        const button = document.getElementById('submitBooking');
        if (button) {
          button.classList.add('green');
          setTimeout(() => button.classList.remove('green'), 3000);
        }
        setFormData({
          customerNotes: '',
          firstName: '',
          // middleName: '',
          lastName: '',
          // sex: '',
          // dob: '',
          phone: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          email: '',
          zip: '',
          // selectedServices: [],
          endTime: '',
          startTime: ''
        })
        // setSelectedServices('');
      }
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };

  useEffect(() => {
    fetchServices();
  }, []);

  const handleTimeChange = (date, field) => {
    if (field === 'startTime') {
      const formattedDate = date ? moment(date).format('YYYY-MM-DDTHH:mm') : '';

      setFormData(prevState => ({
        ...prevState,
        [field]: formattedDate,
        ...(field === 'startTime' && { endTime: calculateEndTime(formattedDate) })
      }));
    }
  };

  const calculateEndTime = (startTime) => {
    const selectedService = services.find(service => service.uuid === selectedServices);
    if (!startTime || !selectedService) {
      console.error('Invalid input for calculating end time');
      return '';
    }

    const duration = parseInt(selectedService.duration) + parseInt(selectedService.bufferTimeAfter);
    const startDate = new Date(startTime);

    if (isNaN(startDate.getTime())) {
      console.error('Invalid start time');
      return '';
    }

    const endDate = new Date(startDate.getTime() + duration * 60000);
    const localEndTime = new Date(endDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().slice(0, 16);
    return localEndTime;
  };

  const setPatient = (selectedPatient) => {
    setFormData({
      customerNotes: selectedPatient.customerNotes,
      firstName: selectedPatient.firstName,
      lastName: selectedPatient.lastName,
      phone: selectedPatient.phone,
      address1: selectedPatient.address1,
      address2: selectedPatient.address2,
      city: selectedPatient.city,
      state: selectedPatient.state,
      email: selectedPatient.email,
      zip: selectedPatient.zipcode,
    })
    setPhoneSearch('')
    fetchTravelFee(selectedPatient.zipcode);
  }
  const fetchServices = async () => {
    try {
      const response = await fetch('/api/admin/getServices', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          staffId: user.id,
          acl: user.acl,
        })
      });
      const data = await response.json();
      setServices(data.filter(service => !service.AddOn));
      // setSelectedServices(data[0].uuid)
      setSelectedServices('f1adb47c-e5de-43ae-93b1-a1abbeeb47fb')
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };

  useEffect(() => {
    if (showPrimaryNursesOnly) {
      setFilteredNurses(nurses.filter(nurse => nurse.PrimaryCityCalendarID === parseInt(cityId)));
    } else {
      setFilteredNurses(nurses);
    }
  }, [showPrimaryNursesOnly, nurses, cityId]);

  // Add this useEffect to handle URL parameters on component mount
  useEffect(() => {
    const startTimeParam = searchParams.get('startTime');
    const nurseParam = searchParams.get('nurse');

    if (startTimeParam) {
      // Validate and format the start time
      const date = new Date(startTimeParam);
      if (!isNaN(date.getTime())) {
        const formattedDate = moment(date).format('YYYY-MM-DDTHH:mm');
        setFormData(prev => ({
          ...prev,
          startTime: formattedDate
        }));
      }
    }

    if (nurseParam) {
      setSelectedNurse(nurseParam);
    }
  }, []);

  return (
    <div className='adminParent adminTabSection'>
      <h2> Create a new booking</h2>
      <form onSubmit={handleSubmit}>
        <table style={{ maxWidth: '100vw' }}>
          <tbody>
            <tr>
              <td><label>Existing Patient? <br/>Search by phone:</label></td>
              <td><input name="customerNotes" value={phoneSearch} onChange={(e) => setPhoneSearch(e.target.value)} /></td><br/>
            </tr>
            {patientsFromPhone.length > 0 && patientsFromPhone.sort((a, b) => {
              if (a.firstName.toUpperCase() < b.firstName.toUpperCase()) {
                return -1;
              }
              if (a.firstName.toUpperCase() > b.firstName.toUpperCase()) {
                return 1;
              } else { return 0; }
            }).map((eachPatient, index) => {
              return (<tr key={index}>
                <td><button type="button" onClick={() => setPatient(eachPatient)}>{eachPatient.firstName} {eachPatient.lastName}</button></td>
              </tr>)
            })}
            <br/>
            <tr>
              <td><label>Customer Notes</label></td>
              <td><textarea name="customerNotes" value={formData.customerNotes} onChange={handleChange} /></td>
            </tr>
            <tr>
              <td><label>First Name</label></td>
              <td><input type="text" name="firstName" value={formData.firstName} onChange={handleChange} /></td>
            </tr>
            {/* <tr>
              <td><label>Middle Name</label></td>
              <td><input type="text" name="middleName" value={formData.middleName} onChange={handleChange} /></td>
            </tr> */}
            <tr>
              <td><label>Last Name</label></td>
              <td><input type="text" name="lastName" value={formData.lastName} onChange={handleChange} /></td>
            </tr>
            {/* <tr>
              <td><label>Sex</label></td>
              <td>
                <select name="sex" value={formData.sex} onChange={handleChange}>
                  <option value="">Select...</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </td>
            </tr>
            <tr>
              <td><label>DOB</label></td>
              <td><input type="date" name="dob" value={formData.dob} onChange={handleChange} /></td>
            </tr> */}
            <tr>
              <td><label>Phone</label></td>
              <td><input type="tel" name="phone" value={formData.phone} onChange={handleChange} /></td>
            </tr>
            <tr>
              <td><label>Email</label></td>
              <td><input type="text" name="email" value={formData.email} onChange={handleChange} /></td>
            </tr>
            <tr>
              <td><label>Address 1</label></td>
              <td><input type="text" name="address1" value={formData.address1} onChange={handleChange} /></td>
            </tr>
            <tr>
              <td><label>Address 2</label></td>
              <td><input type="text" name="address2" value={formData.address2} onChange={handleChange} /></td>
            </tr>
            <tr>
              <td><label>City</label></td>
              <td><input type="text" name="city" value={formData.city} onChange={handleChange} /></td>
            </tr>
            <tr>
              <td><label>State</label></td>
              <td><input type="text" name="state" value={formData.state} onChange={handleChange} /></td>
            </tr>
            <tr>
              <td><label>Zip</label></td>
              <td><input type="text" name="zip" value={formData.zip} onChange={handleChange} onBlur={handleBlur} /></td>
            </tr>
            <tr>
              <td><label>Start Time</label></td>
              <td>
                <ReactDatePicker
                  selected={formData.startTime ? new Date(formData.startTime) : null}
                  onChange={(date) => handleTimeChange(date, 'startTime')}
                  showTimeSelect
                  timeIntervals={15} // 15 minute intervals
                  timeFormat="hh:mm a"
                  dateFormat="yyyy-MM-dd hh:mm a"
                  minDate={new Date()} // Set the minimum date to the current date
                  placeholderText="Set Start Time"
                  name="startTime"
                />
                {/* <input type="datetime-local" name="startTime" value={formData.startTime} onChange={handleChange} /> */}
              </td>
            </tr>
            <tr>
              <td><label>End Time</label></td>
              <td>
                <ReactDatePicker
                  selected={formData.endTime ? new Date(formData.endTime) : null}
                  // onChange={handleChange}
                  showTimeSelect
                  timeIntervals={15} // 15 minute intervals
                  timeFormat="hh:mm a"
                  dateFormat="yyyy-MM-dd hh:mm a"
                  minDate={new Date()} // Set the minimum date to the current date
                  placeholderText="End Time"
                  name="startTime"
                  disabled
                />
                {/* <input type="datetime-local" name="endTime" value={formData.endTime} readOnly /> */}
              </td>
            </tr>
            <tr>
              <td><label>Services</label> </td>
            </tr>
            <tr>
              <td colSpan="2">
                <select
                  //   multiple
                  style={{ maxWidth: '100%' }}
                  name="selectedServices"
                  value={selectedServices}
                  onChange={handleChange}
                  required
                >
                  {services.length > 0 && services.sort((a, b) => { return a.name > b.name ? 1 : -1 }).map(service => (
                    <option key={service.uuid} value={service.uuid}>
                      {service.name} - ${service.price}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td><label>Nurse</label></td>
              <td>
                <select
                  name="selectedNurse"
                  value={selectedNurse}
                  onChange={(e) => { setSelectedNurse(e.target.value) }}
                  required
                >
                  {filteredNurses.length > 0 && filteredNurses.map(nurse => (
                    <option key={nurse.id} value={nurse.id}>
                      {nurse.firstName}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <div style={{ marginBottom: '10px' }}>
                  <label>
                    <input
                      type="checkbox"
                      checked={showPrimaryNursesOnly}
                      onChange={(e) => setShowPrimaryNursesOnly(e.target.checked)}
                    />
                    Show only nurses assigned to this city
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <button 
          type="button" 
          onClick={() => checkNurseAvailability(formData.startTime, formData.endTime)}
          disabled={!formData.startTime || !selectedServices}
        >
          Check Nurse Availability
        </button>
        <button type="submit" id="submitBooking" disabled={!isFormValid}>Submit Booking</button>
      </form>
      <p>
        <button className="navigate-button" onClick={() => navigate('/admin/byCity/' + cityId)}>
          {'<'} Admin home
        </button>
      </p>
      <div className='adminParent adminTabSection adminTable'>
        <h2>Nurse Availability</h2>
        <table>
          <thead>
            <tr>
              <th>start time</th>
              <th>staff</th>
            </tr>
          </thead>
          <tbody>
            {timeSlots.map((timeSlot, index) => (
              <tr key={index}>
                <td>{moment(timeSlot.start).format('MM/DD/YYYY hh:mm a')}</td>
                <td>{timeSlot.staff.map(staff => staff.firstName).join(', ')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NewBookingForm;
