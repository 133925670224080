// src/components/MoveAppointment.jsx
import React from 'react';
import ReactDatePicker from 'react-datepicker';

const MoveAppointment = ({ localBookingData, nurses, cities, handleChange, moveBooking }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    moveBooking(localBookingData.primaryStaffId, localBookingData.cityCalendarId, localBookingData.startTime, e);
  };

  return (
    <div className="adminTextField">
      <h3>Move Appointment</h3>
      <form onSubmit={handleSubmit}>
        <label>
          Time:
          <ReactDatePicker
            selected={localBookingData.startTime ? new Date(localBookingData.startTime) : null}
            onChange={(date) => handleChange(date, 'startTime')}
            showTimeSelect
            timeIntervals={15}
            timeFormat="hh:mm a"
            dateFormat="yyyy-MM-dd hh:mm a"
            minDate={new Date()}
            placeholderText="Change Time"
            required
          />
        </label>
        <br />
        <label>
          Nurse:
          <select
            value={localBookingData.primaryStaffId}
            onChange={(e) => handleChange(e, 'primaryStaffId')}
            required
          >
            {nurses.map(nurse => (
              <option key={nurse.id} value={nurse.id}>
                {nurse.firstName} {nurse.lastName}
              </option>
            ))}
          </select>
        </label>
        {'  '}
        <label>
          City:
          <select
            value={localBookingData.cityCalendarId}
            onChange={(e) => {
              if (window.confirm("Are you sure you want to change the city?")) {
                handleChange(e, 'cityCalendarId');
              }
            }}
            required
          >
            <option value="">Select a city</option>
            {cities.map(city => (
              <option key={city.id} value={city.id}>
                {city.name}
              </option>
            ))}
          </select>
        </label>
        <br />
        <button type="submit">Move Booking</button>
      </form>
    </div>
  );
};

export default MoveAppointment;