// src/components/CustomerServiceNotes.jsx
import React, { useState } from 'react';
import { updateBooking as updateBookingApi } from '../../api/bookingApi.js';
import { formatDate } from '../../utility/module.js';

const CustomerServiceNotes = ({ localBookingData, bookingData, user }) => {
  const [notes, setNotes] = useState(bookingData.customerServiceCommunicationJSON || []);
  const [noteReason, setNoteReason] = useState('late');
  const [noteText, setNoteText] = useState('');

  const handleNoteSubmit = async (e) => {
    e.preventDefault();
    const newNote = {
      reason: noteReason,
      text: noteText,
      date: new Date().toISOString(),
      author: user.email,
      provider: bookingData.primaryStaffId,
    };
    const updatedNotes = bookingData.customerServiceCommunicationJSON
      ? [...bookingData.customerServiceCommunicationJSON, newNote]
      : [newNote];
    try {
      await updateBookingApi({
        uuid: localBookingData.uuid,
        customerServiceCommunicationJSON: updatedNotes,
      });
      setNotes(updatedNotes);
      setNoteReason('late');
      setNoteText('');
    } catch (error) {
      console.error('Error updating booking with new note:', error);
    }
  };

  return (
    <div className='adminTextField'>
      <h3>Customer Service Notes</h3>
      {notes.length > 0 && (
        <div className="adminTextField">
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Reason</th>
                <th>Note</th>
                <th>Author</th>
              </tr>
            </thead>
            <tbody>
              {notes.map((note, index) => (
                <tr key={index}>
                  <td>{formatDate(note.date)}</td>
                  <td>{note.reason}</td>
                  <td>{note.text}</td>
                  <td>{note.author}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="">
        <form onSubmit={handleNoteSubmit}>
          <label>
            Reason:{' '}
            <select value={noteReason} onChange={(e) => setNoteReason(e.target.value)} required>
              <option value="late">Late</option>
              <option value="nurse Issue">Nurse Issue other than stick</option>
              <option value="missed Stick">Missed stick</option>
              <option value="scheduling Impossible">Scheduling impossible</option>
              <option value="difficult Patient">Difficult Patient</option>
              <option value="Technical problem">Technical problem</option>
            </select>
          </label>
          <label>
            <br />
            <textarea
              value={noteText} 
              onChange={(e) => setNoteText(e.target.value)} 
              required 
              rows={4}
              // style={{ width: '90%' }}
              placeholder="Add a new customer service note..."
            />
          </label>
          <br />
          <button type="submit">Submit Note</button>
        </form>
      </div>
    </div>
  );
};

export default CustomerServiceNotes;
