// src/components/CustomerServiceContent.jsx
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { getNurses, getCities, updatePatientInfo as updatePatientInfoApi, updateBooking as updateBookingApi, moveBooking as moveBookingApi } from '../api/bookingApi';
import CancelBookingForm from './customerServiceTab/CancelBookingForm';
import BookingStatusToggle from './customerServiceTab/BookingStatusToggle';
import UpdatePatientInfo from './customerServiceTab/UpdatePatientInfo';
import MoveAppointment from './customerServiceTab/MoveAppointment';
import CalendarBlockDuration from './customerServiceTab/CalendarBlockDuration';
import AdminNotes from './customerServiceTab/AdminNotes';
import CustomerServiceNotes from './customerServiceTab/CustomerServiceNotes';

const CustomerServiceContent = ({ bookingData, handleFieldChange }) => {
  const [localBookingData, setLocalBookingData] = useState(bookingData);
  const [savedBookingData, setSavedBookingData] = useState(bookingData);
  const [nurses, setNurses] = useState([]);
  const [cities, setCities] = useState([]);
  const [user, setUser] = useState({});
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [patientNotes, setPatientNotes] = useState(bookingData.patient?.adminNotes || '');

  // Fetch authentication status
  useEffect(() => {
    fetch('/api/auth/status')
      .then(res => res.json())
      .then(data => {
        if (data.authenticated) {
          setUser(data.user);
        } else {
          window.location.href = '/login';
        }
      })
      .catch(error => console.error('Error fetching auth status:', error));
  }, []);

  // Update local state when bookingData prop changes
  useEffect(() => {
    setLocalBookingData(bookingData);
    setSavedBookingData(bookingData);
  }, [bookingData]);

  // Fetch nurses and cities on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [fetchedNurses, fetchedCities] = await Promise.all([getNurses(), getCities()]);
        setNurses(fetchedNurses);
        setCities(fetchedCities);
      } catch (error) {
        console.error('Error fetching nurses and cities:', error);
      }
    };
    fetchData();
  }, []);

  // Generic field change handler
  const handleChange = (event, field) => {
    if (field === 'startTime') {
      setLocalBookingData({ ...localBookingData, startTime: event });
      handleFieldChange(field, event);
    } else {
      setLocalBookingData({ ...localBookingData, [field]: event.target.value });
      handleFieldChange(field, event.target.value);
    }
  };

  // Toggle nested boolean fields (e.g., "patient.isVIP")
  const handleToggleChangeBoolean = (fieldPath) => {
    setLocalBookingData(prevData => {
      const fieldParts = fieldPath.split('.');
      let updatedData = { ...prevData };
      let current = updatedData;
      fieldParts.slice(0, -1).forEach(part => {
        current[part] = { ...current[part] };
        current = current[part];
      });
      const lastField = fieldParts[fieldParts.length - 1];
      current[lastField] = !current[lastField];
      return updatedData;
    });
  };

  // Save booking changes with green flash effect
  const handleSave = async (e) => {
    e.preventDefault();
    const changes = {};
    const detectChanges = (saved, local, prefix = '') => {
      Object.keys(local).forEach(key => {
        if (typeof local[key] === 'object' && local[key] !== null && !Array.isArray(local[key])) {
          detectChanges(saved[key] || {}, local[key], `${prefix}${key}.`);
        } else if (local[key] !== saved[key]) {
          changes[`${prefix}${key}`] = local[key];
        }
      });
    };
    detectChanges(savedBookingData, localBookingData);
    if (Object.keys(changes).length > 0) {
      const updatedData = { uuid: localBookingData.uuid, ...changes };
      try {
        await updateBookingApi(updatedData);
        setSavedBookingData({ ...savedBookingData, ...changes });
        await handleUpdatePatientInfo();
        setSaveSuccess(true);
        setTimeout(() => setSaveSuccess(false), 3000);
      } catch (error) {
        console.error('Error updating booking:', error);
      }
    } else {
      console.log('No changes to save');
    }
  };

  const handleUpdatePatientInfo = async () => {
    try {
      const { uuid, isVIP, blackList } = localBookingData.patient;
      await updatePatientInfoApi({
        uuid,
        isVIP,
        blackList,
        adminNotes: patientNotes,
      });
    } catch (error) {
      console.error('Error updating patient info:', error);
    }
  };

  // Handle moving the booking
  const moveBooking = async (nurseId, cityId, newStartTime, e) => {
    e.preventDefault();
    const button = e.target;
    try {
      await moveBookingApi({
        uuid: localBookingData.uuid,
        nurseId: Number(nurseId),
        cityId,
        newStartTime,
      });
      button.classList.add('green');
      setTimeout(() => {
        button.classList.remove('green');
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error('Error moving booking:', error);
    }
  };

  return (
    <div className="adminParent adminTabSection">
      <CancelBookingForm 
        localBookingData={localBookingData}
        bookingData={bookingData}
        user={user}
      />
      <BookingStatusToggle 
        localBookingData={localBookingData}
        handleToggleChangeBoolean={handleToggleChangeBoolean}
        handleSave={handleSave}
        saveSuccess={saveSuccess}
      />
      <UpdatePatientInfo
        localBookingData={localBookingData}
        patientNotes={patientNotes}
        setPatientNotes={setPatientNotes}
        updatePatientInfo={handleUpdatePatientInfo}
        saveSuccess={saveSuccess}
        handleToggleChangeBoolean={handleToggleChangeBoolean}
      />
      <MoveAppointment
        localBookingData={localBookingData}
        nurses={nurses}
        cities={cities}
        handleChange={handleChange}
        moveBooking={moveBooking}
      />
      <CalendarBlockDuration localBookingData={localBookingData} />
      <AdminNotes user={user} localBookingData={localBookingData} />
      <CustomerServiceNotes 
        localBookingData={localBookingData}
        bookingData={bookingData}
        user={user}
      />
    </div>
  );
};

export default CustomerServiceContent;